import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import SliderShape from "../SubComponents/SliderShape";
import { GlobalContext } from "../../context/GlobalState";
import { listCourses } from "../../context/actions/courseActions";
import { showBanner } from "../../context/actions/showBanner";
import "react-toastify/dist/ReactToastify.css";
import { showCategories } from "../../context/actions/showCategories";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import "./tab.css";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { DraggableSlider } from "../DraggableSlider/DraggableSlider";
import { showLinks } from "../../context/actions/showLinks";
import { metaTags } from "../../context/actions/metaTags";
import HomePageCarousal from "../SubComponents/HomePageCarousel/HomePageCarousel";
import Sections from "./Sections/Sections";
import i18n from "../../i18n/i18n";

function Home() {
  const navigate = useNavigate();
  const [banners, setBanners] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [
    currentTabContainerScrollPosition,
    setCurrentTabContainerScrollPosition,
  ] = useState(0);
  const [filteredCourses, setFilteredCourses] = useState(null);
  // const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const tabContainerRef = useRef(null);
  const [numCoursesToShow, setNumCoursesToShow] = useState(15);
  const [spinner, setSpinner] = useState(false);

  const handleShowMoreCourses = () => {
    setNumCoursesToShow(numCoursesToShow + 3);
  };

  useEffect(() => {
    // Call the updateMetaTags function with the dynamic values
    const meta_tags = metaTags(1, 1);
    meta_tags.then((res) => {
      if (window.updateMetaTags) {
        window.updateMetaTags(res.tag, res.description);
      }
    });
  }, []);

  useEffect(() => {
    showLinks();
  }, []);

  // const handleNextCategory = () => {
  //   const newIndex = (currentCategory + 1) % categories.length;
  //   setCurrentCategory(newIndex);
  // };

  // const handlePrevCategory = () => {
  //   const newIndex =
  //     (currentCategory - 1 + categories.length) % categories.length;

  //   setCurrentCategory(newIndex);
  // };

  // const handleTabContainerScroll = (event) => {
  //   setCurrentTabContainerScrollPosition(event.target.scrollLeft);
  // };

  const { courseState, courseDispatch, singleCartState, singlecartDispatch } =
    useContext(GlobalContext);

  const count = 0;
  const courseList = courseState.courses;

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleCategoryChange = (index) => {
    setCurrentCategory(index);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    // Filter courses based on category and search query
    if (courseList && courseList.length === 0) {
      return;
    }
    const filtered = courseList.filter((course) => {
      return (
        currentCategory === 0 && //courseList[currentCategory] &&
        //course.category_name === courseList[currentCategory].category_name)) &&
        (searchQuery === "" ||
          course.course_name.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    });

    setFilteredCourses(filtered);
  }, [searchQuery, currentCategory, courseList]);

  useEffect(() => {
    // scroll to the top of the page on mount
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setSpinner(true);
    const response = showBanner();
    response.then((response) => {
      setSpinner(false);
      setBanners(response);
    });
  }, []);

  // useEffect(() => {
  //   const response = showCategories();
  //   response.then((res) => {
  //     setCategories(["All", ...res]);
  //   });
  // }, []);

  useEffect(() => {
    const response = showBanner();
    response.then((data) => setBanners(data));
  }, []);

  useEffect(() => {
    listCourses(courseDispatch);
  }, [courseDispatch]);

  const CourseListItems = ({ course }) => {
    const { status } = course;
    return (
      <div className="col-lg-3 col-md-6 d-flex">
        {/* Single Courses Start */}
        <div className="single-courses w-100">
          <div className="courses-images">
            {isLoading && <Spinner animation="border" />}

            {status === "coming soon" || status !== "active" ? (
              <Link to={`/course/${course.slug ?? course.id}`}>
                <img
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    "uploads/course_items/" +
                    course.course_image
                  }
                  alt={`${course.course_name}`}
                  onLoad={handleImageLoad}
                  style={{ visibility: isLoading ? "none" : "visible" }}
                />
              </Link>
            ) : (
              <Link to={`/course/${course.slug ?? course.id}`}>
                <img
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    "uploads/course_items/" +
                    course.course_image
                  }
                  alt={`${course.course_name}`}
                  onLoad={handleImageLoad}
                  style={{ visibility: isLoading ? "none" : "visible" }}
                />
              </Link>
            )}
          </div>
          <div className="courses-content">
            <div className="courses-author">
              <div className="author">
                <div className="author-thumb">
                  {isLoading && <Spinner animation="border" />}
                  {/* <Link to={`/course-detail/${course.id}`}><img src={'http://localhost:9000/uploads/instructor_images/'+course.instructor_image}  */}
                  <Link to={`/course/${course.slug ?? course.id}`}>
                    <img
                      src={
                        course.instructor_image !== "" &&
                        process.env.REACT_APP_IMAGE_URL +
                          "/uploads/instructor_images/" +
                          course.instructor_image
                      }
                      alt={`${course.instructor_name}`}
                      onLoad={handleImageLoad}
                      style={{ display: isLoading ? "none" : "block" }}
                    />
                  </Link>
                </div>
                <div className="author-name">
                  <Link
                    className="name"
                    to={`/course/${course.slug ?? course.id}`}
                  >
                    {course.instructor_name}
                  </Link>
                </div>
              </div>
            </div>
            <div className="tag-container">
              <div className="tag">
                <Link to={`/course/${course.slug ?? course.id}`}>
                  {course.category_name}
                </Link>
              </div>
            </div>
            <h4 className="title" style={{ marginTop: "-2px" }}>
              <Link to={`/course/${course.slug ?? course.id}`}>
                {course.course_name}
              </Link>
            </h4>
            <div className="courses-meta" style={{ marginTop: "-8px" }}>
              <span>
                {" "}
                <i className="icofont-clock-time" /> {course.duration}
              </span>
              <span>
                {" "}
                <i className="icofont-read-book" /> {course.no_lectures}{" "}
              </span>
            </div>
            {status === "coming soon" || status !== "active" ? (
              <div
                className="courses-price-review d-block bg-primary text-white text-center font-weight-bold"
                style={{ fontWeight: "bold" }}
              >
                Coming Soon
              </div>
            ) : (
              <div className="courses-price-review bg-primary text-white font-weight-bold">
                <div className="courses-price" style={{ fontWeight: "bold" }}>
                  {course.price === 0 ||
                  course.price === "" ||
                  course.price === undefined ||
                  course.price === null ? (
                    "Free"
                  ) : (
                    <span className="sale-price" style={{ fontWeight: "bold" }}>
                      
                      {i18n.t('AED')} {course.price}
                    </span>
                  )}
                  <span className="old-parice">{course.previous_price}</span>
                </div>
                <div className="courses-review">
                  <a href={`/course/${course.slug ?? course.id}`}>Read More</a>
                </div>
                {/*<div className="courses-review">
                     <span className="rating-count">4.9</span>
                     <span className="rating-star">
                       <span className="rating-bar" style={{width: '80%'}} />
                     </span>
                   </div>*/}
              </div>
            )}
          </div>
        </div>
        {/* Single Courses End */}
      </div>
    );
  };

  return (
    <>
      {spinner && <LoadingSpinner />}
      <div className="section home-slider-section">
        <HomePageCarousal />
      </div>
      <Sections />
      <div className="section">
        <div className="container">
          {/* All Courses Top Start */}
          {/* <div className="courses-top"> */}
          {/* Section Title Start */}
          {/* <div className="section-title shape-01 mt-2">
              <h2 className="main-title">
                All <span>Courses</span> of Upgrade Skills
              </h2>
            </div> */}
          {/* Section Title End */}
          {/* Courses Search Start */}
          {/* <div className="courses-search">
              <form action="#">
                <input
                  type="text"
                  placeholder="Search courses"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <button>
                  <i className="flaticon-magnifying-glass" />
                </button>
              </form>
            </div> */}
          {/* Courses Search End */}
          {/* </div> */}

          {/**Draggable Slider */}
          {/* <div
            style={{
              marginTop: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center  ",
            }}
          >
            <DraggableSlider
              object={categories}
              handleCategoryChange={handleCategoryChange}
              currentCategory={currentCategory}
            />
          </div> */}

          <div className="tab-content courses-tab-content">
            <div className="tab-pane fade show active" id="tabs1">
              {/* All Courses Wrapper Start */}
              <div className="courses-wrapper">
                <div className="row">
                  {filteredCourses && filteredCourses.length > 0 ? (
                    <>
                      {filteredCourses
                        .slice(0, numCoursesToShow)
                        .map((course, key) => (
                          <CourseListItems course={course} key={key} />
                        ))}
                      {numCoursesToShow < filteredCourses.length && (
                        <div className="courses-btn text-center">
                          <button
                            className="btn btn-secondary btn-hover-primary"
                            onClick={handleShowMoreCourses}
                          >
                            More Courses
                          </button>
                        </div>
                      )}
                    </>
                  ) : filteredCourses === null ? (
                    <center>
                      <Spinner animation="border" variant="primary" />
                    </center>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <h1>No Courses</h1>
                    </div>
                  )}
                </div>
              </div>
              {/* All Courses Wrapper End */}
            </div>
          </div>
          {/* All Courses tab content End */}
          {/* All Courses BUtton Start */}

          {/* All Courses BUtton End */}
        </div>
      </div>

      <div className="section section-padding-02 mb-5">
        <div className="container">
          {/* Call to Action Wrapper Start */}
          <div className="call-to-action-wrapper">
            <img
              className="cat-shape-01 animation-round"
              src={process.env.PUBLIC_URL + "/images/shape/shape-12.png"}
            />
            <img
              className="cat-shape-02"
              src={process.env.PUBLIC_URL + "/images/shape/shape-13.svg"}
            />
            <img
              className="cat-shape-03 animation-round"
              src={process.env.PUBLIC_URL + "/images/shape/shape-12.png"}
            />
            <div className="row align-items-center">
              <div className="col-md-6">
                {/* Section Title Start */}
                <div className="section-title shape-02">
                  <h5 className="sub-title">{i18n.t('JoinSecBeAnInstructor')}</h5>
                  <h2 className="main-title">
                    {i18n.t('JoinUpgradeSkillsAsAnInstructor')}<span> {i18n.t('Instructor')}!!</span>
                  </h2>
                </div>
                {/* Section Title End */}
              </div>
              <div className="col-md-6">
                <div className="call-to-action-btn">
                  <Link
                    className="btn btn-primary btn-hover-dark"
                    to="/contact"
                  >
                    {i18n.t('JoinDropInformationBtn')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Call to Action Wrapper End */}
        </div>
      </div>

      {/* <div className="section section-padding mt-n1">
        <div className="container">
          
          <div className="section-title shape-03 text-center">
            
            <h2 className="main-title">
              How It <span> Works?</span>
            </h2>
          </div>
         
          <div className="how-it-work-wrapper">
           
            <div className="single-work">
              <img
                className="shape-1"
                src={process.env.PUBLIC_URL + "/images/shape/shape-15.png"}
              />
              <div className="work-icon">
                <i className="flaticon-transparency" />
              </div>
              <div className="work-content">
                <h3 className="title">Find Your Course</h3>
                <br />
                <br />
              
              </div>
            </div>
            
            <div className="work-arrow">
              <img
                className="arrow"
                src={process.env.PUBLIC_URL + "/images/shape/shape-17.png"}
              />
            </div>
            
            <div className="single-work">
              <img
                className="shape-2"
                src={process.env.PUBLIC_URL + "/images/shape/shape-15.png"}
              />
              <div className="work-icon">
                <i className="flaticon-forms" />
              </div>
              <div className="work-content">
                <h3 className="title">Book A Course</h3>
                <br />
                <br />
                
              </div>
            </div>
            
            <div className="work-arrow">
              <img
                className="arrow"
                src={process.env.PUBLIC_URL + "/images/shape/shape-17.png"}
              />
            </div>
          
            <div className="single-work">
              <img
                className="shape-3"
                src={process.env.PUBLIC_URL + "/images/shape/shape-16.png"}
              />
              <div className="work-icon">
                <i className="flaticon-badge" />
              </div>
              <div className="work-content">
                <h3 className="title">Sharpen your skills</h3>
                <br />
                <br />
                
              </div>
            </div>
            
          </div>
        </div>
      </div> */}

      <SliderShape />

      {/*<div className="section-title shape-03 text-center">
          <h5 className="sub-title">Latest News</h5>
          <h2 className="main-title">Educational Tips &amp; <span> Tricks</span></h2>
      </div>

      

      <div className="blog-wrapper">
        <div className="row container-fluid" style={{padding:'10px'}}>
             {courseList ? courseList && filteredCourses.slice(3, 6).map((course, key) => (
                    <CourseListItems course={course} key={course.id} />
                  )) : <Spinner/>}     
        </div>
             </div>*/}
    </>
  );
}

export default Home;
