import React from 'react'
import './found.css'


export function NotFound() {
  return (
    <div className="not-found">
       
            <h1>404</h1>
            <img src="https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif" />
            <h5>Looks Like you are lost</h5>
            <p>the page you are looking for not avaible!</p>
            <a class="btn btn-primary btn-hover-dark" href="/">Go to Home</a>
        
    </div>
  )
}


