import axios from "axios";


export const sendInstructorDetails = async (formData) => {
    const {email, phone, course, fullname, address} = formData;
    try {
        const response = await axios.post("save-instructor-detail",{
          'email':email,
          'phone':phone,
          'fullname': fullname,
          'address':address ?? '',
          'course':course
        });
        return response.data;
      } catch (error) {
        console.error(error);
      }  
}