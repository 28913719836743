/* eslint-disable jsx-a11y/alt-text */
import { React, useState } from "react";
import { Link } from "react-router-dom";
import DownloadApp from "../SubComponents/DownloadApp";
import { useContext, useEffect } from "react";
import { GlobalContext } from "../../context/GlobalState";
import { listCourses } from "../../context/actions/courseActions";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { metaTags } from "../../context/actions/metaTags";
import i18n from "../../i18n/i18n";

function Courses() {
  const navigate = useNavigate();
  const { courseState, courseDispatch, singleCartState, singlecartDispatch } =
    useContext(GlobalContext);
  const count = 0;
  const [isLoading, setIsLoading] = useState(true);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const courseList = courseState.courses;
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    listCourses(courseDispatch);
  }, [courseDispatch]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    // scroll to the top of the page on mount
    window.scrollTo(0, 0);

    // Call the updateMetaTags function with the dynamic values
    const meta_tags = metaTags(1, 5);
    meta_tags.then((res) => {
      if (window.updateMetaTags) {
        window.updateMetaTags(res.tag, res.description);
      }
    });
  }, []);

  useEffect(() => {
    // console.log("courseList:", courseList);
    // console.log("searchQuery:", searchQuery);

    const filtered = courseList.filter((course) => {
      return (
        course.course_name.toLowerCase().indexOf(searchQuery.toLowerCase()) !==
        -1
      );
    });

    // console.log(filtered)
    setFilteredCourses(filtered);
  }, [searchQuery, courseList]);

  // useState(() => {
  //   console.log(filteredCourses)
  // },[filteredCourses])

  const CourseListItems = ({ course }) => {
    const { status } = course;

    return (
      <div className="col-lg-3 col-md-6 d-flex">
        {/* Single Courses Start */}
        <div className="single-courses w-100">
          <div className="courses-images">
            {isLoading && <Spinner animation="border" />}

            {status === "coming soon" || status !== "active" ? (
              <Link to={`/course/${course.slug ?? course.id}`}>
                <img
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    "uploads/course_items/" +
                    course.course_image
                  }
                  onLoad={handleImageLoad}
                  style={{ visibility: isLoading ? "none" : "visible" }}
                />
              </Link>
            ) : (
              <Link to={`/course/${course.slug ?? course.id}`}>
                <img
                  src={
                    process.env.REACT_APP_IMAGE_URL +
                    "uploads/course_items/" +
                    course.course_image
                  }
                  onLoad={handleImageLoad}
                  style={{ visibility: isLoading ? "none" : "visible" }}
                />
              </Link>
            )}
          </div>
          <div className="courses-content">
            <div className="courses-author">
              <div className="author">
                <div className="author-thumb">
                  {isLoading && <Spinner animation="border" />}
                  {/* <Link to={`/course-detail/${course.id}`}><img src={'http://localhost:9000/uploads/instructor_images/'+course.instructor_image}  */}
                  <Link to={`/course/${course.slug ?? course.id}`}>
                    <img
                      src={
                        course.instructor_image !== "" &&
                        process.env.REACT_APP_IMAGE_URL +
                          "/uploads/instructor_images/" +
                          course.instructor_image
                      }
                      onLoad={handleImageLoad}
                      style={{ display: isLoading ? "none" : "block" }}
                    />
                  </Link>
                </div>
                <div className="author-name">
                  <Link
                    className="name"
                    to={`/course/${course.slug ?? course.id}`}
                  >
                    {course.instructor_name}
                  </Link>
                </div>
              </div>
            </div>
            <div className="tag-container">
              <div className="tag">
                <Link to={`/course/${course.slug ?? course.id}`}>
                  {course.category_name}
                </Link>
              </div>
            </div>
            <h4 className="title" style={{ marginTop: "-2px" }}>
              <Link to={`/course/${course.slug ?? course.id}`}>
                {course.course_name}
              </Link>
            </h4>
            <div className="courses-meta" style={{ marginTop: "-8px" }}>
              <span>
                {" "}
                <i className="icofont-clock-time" /> {course.duration}
              </span>
              <span>
                {" "}
                <i className="icofont-read-book" /> {course.no_lectures}{" "}
              </span>
            </div>
            {status === "coming soon" || status !== "active" ? (
              <div
                className="courses-price-review d-block bg-primary text-white text-center font-weight-bold"
                style={{ fontWeight: "bold" }}
              >
                {i18n.t('CommingSoon')}
              </div>
            ) : (
              <div className="courses-price-review bg-primary text-white font-weight-bold">
                <div className="courses-price" style={{ fontWeight: "bold" }}>
                  {course.price === 0 ||
                  course.price === "" ||
                  course.price === undefined ||
                  course.price === null ? (
                    "Free"
                  ) : (
                    <span className="sale-price" style={{ fontWeight: "bold" }}>
                      {" "}
                      AED {course.price}
                    </span>
                  )}
                  <span className="old-parice">{course.previous_price}</span>
                </div>
                <div className="courses-review">
                  <a href={`/course/${course.slug ?? course.id}`}>Read More</a>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Single Courses End */}
      </div>
    );
  };

  const CourseListItems1 = ({ course }) => {
    const { status } = course;
    return (
      <div className="col-lg-4 col-md-6">
        <div className="single-courses">
          <div className="courses-images">
            {isLoading && <Spinner animation="border" />}
            <Link to={`/course-detail/${course.slug ?? course.id}`}>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "uploads/course_items/" +
                  course.course_image
                }
                alt="Courses"
                onLoad={handleImageLoad}
                style={{ visibility: isLoading ? "none" : "visible" }}
              />
            </Link>
          </div>
          <div className="courses-content">
            <div className="courses-author">
              <div className="author">
                <div className="author-thumb">
                  {isLoading && <Spinner animation="border" />}
                  <Link to={`/course-detail/${course.slug ?? course.id}`}>
                    <img
                      src={
                        process.env.REACT_APP_IMAGE_URL +
                        "uploads/instructor_images/" +
                        course.instructor_image
                      }
                      alt="Author"
                      onLoad={handleImageLoad}
                      style={{ display: isLoading ? "none" : "block" }}
                    />
                  </Link>
                </div>
                <div className="author-name">
                  <Link
                    className="name"
                    to={`/course-detail/${course.slug ?? course.id}`}
                  >
                    {course.instructor_name}
                  </Link>
                </div>
              </div>
              <div className="tag">
                <Link to={`/course-detail/${course.slug ?? course.id}`}>
                  {course.category_name}
                </Link>
              </div>
            </div>
            <h4 className="title">
              <Link to={`/course-detail/${course.slug ?? course.id}`}>
                {course.course_name}
              </Link>
            </h4>
            <div className="courses-meta">
              <span>
                {" "}
                <i className="icofont-clock-time" /> {course.duration}
              </span>
              <span>
                {" "}
                <i className="icofont-read-book" /> {course.no_lectures}{" "}
              </span>
            </div>
            {status === "coming soon" || status !== "active" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <button className="btn btn-primary btn-hover-dark">
                  Coming Soon
                </button>
              </div>
            ) : (
              <div className="courses-price-review bg-primary">
                <div className="courses-price">
                  <span className="sale-parice">{course.price}</span>
                  <span className="old-parice">{course.previous_price}</span>
                </div>
                <div className="courses-review">
                  {/*<span className="rating-count">4.9</span>
                    <span className="rating-star">
                      <span className="rating-bar" style={{width: '80%'}} />
                    </span>*/}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="section page-banner-noshapes">
        {/* <img
          className="shape-1 animation-round"
          src={process.env.PUBLIC_URL + "/images/shape/shape-8.png"}
        />
        <img
          className="shape-2"
          src={process.env.PUBLIC_URL + "/images/shape/shape-23.png"}
        />
        <div className="container">
          <div className="page-banner-content">
            <ul className="breadcrumb">
              <li>
                <Link to="#">Home</Link>
              </li>
              <li className="active">Courses</li>
            </ul>
            <h2 className="title">
              Our <span>Courses</span>
            </h2>
          </div>
        </div>
        <div className="shape-icon-box">
          <img
            className="icon-shape-1 animation-left"
            src={process.env.PUBLIC_URL + "/images/shape/shape-5.png"}
          />
          <div className="box-content">
            <div className="box-wrapper">
              <i className="flaticon-badge"></i>
            </div>
          </div>
          <img
            className="icon-shape-2"
            src={process.env.PUBLIC_URL + "/images/shape/shape-6.png"}
          />
        </div>
        <img
          className="shape-3"
          src={process.env.PUBLIC_URL + "/images/shape/shape-24.png"}
        />
        <img
          className="shape-author"
          src={process.env.PUBLIC_URL + "/images/author/author-11.jpg"}
        /> */}
      </div>

      <div className="section pb-5" style={{ marginTop: "-10px" }}>
        <div className="container">
          {/* <div className="courses-category-wrapper">
            <div
              className="courses-search search-2"
              style={{ maxwidth: "100% !important" }}
            >
              <input
                type="text"
                placeholder="Search courses"
                value={searchQuery}
                onChange={handleSearch}
              />
              <button aria-label="Search">
                <i className="icofont-search" style={{ height: "59px" }}></i>
              </button>
            </div> */}

          {/*<ul className="category-menu">
                        <li><Link className="active" to="#">All Courses</Link></li>
                        <li><Link to="#">Collections</Link></li>
                        <li><Link to="#">Wishlist</Link></li>
                        <li><Link to="#">Archived</Link></li>
  </ul>*/}
          {/* </div> */}

          <div className="courses-wrapper-02">
            <div className="row">
              {filteredCourses.length === 0 ? (
                <center>
                  <Spinner animation="border" variant="primary" />
                </center>
              ) : (
                filteredCourses.map((course, key) => (
                  <CourseListItems course={course} key={course.id} />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
